<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="状态">
        <el-select
          @change="searchClick()"
          v-model="searchForm.status"
          placeholder="请选择查询条件"
        >
          <el-option label="正常" :value="0"></el-option>
          <el-option label="回收站" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`match:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </perContainer>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <el-table-column prop="index" label="排序"> </el-table-column>
      <el-table-column label="kv图">
        <template slot-scope="scope">
          <img :src="scope.row.kv_picture" class="avatar" />
        </template>
      </el-table-column>
      <el-table-column label="跳转类型">
        <template slot-scope="scope">
          {{ linkObj[scope.row.is_link] }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="is_link" label="跳转类型"> </el-table-column> -->
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:save`"
            @click="handleEdit(scope.row)"
            style="margin-left: 10px"
            >编辑</perButton
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id, scope.row.status)"
            >{{ scope.row.status == 0 ? "删除" : "恢复" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="bannerData.view_type == 'add' ? '新增Banner' : '修改Banner'"
      :visible.sync="bannerVisible"
      v-if="bannerVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="750px"
    >
      <el-form ref="bannerForm" :model="bannerData" label-width="200px">
        <el-form-item
          label="排序"
          prop="index"
          :rules="[
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
            {
              validator: validatorInteger,
              message: '请输入大于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="bannerData.index"
            placeholder="请输入排序"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="kv图"
          prop="kv_picture"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
            action="#"
            :http-request="httpUploadRequestKv"
          >
            <img
              v-if="bannerData.kv_picture"
              :src="bannerData.kv_picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item
          id="is_link"
          label-width="200px"
          label="跳转类型"
          prop="match_news_status"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-select
              v-model="bannerData.is_link"
              style="width: 200px"
              @change="clear_content(bannerData.is_link)"
            >
              <el-option :value="1" label="链接"></el-option>
              <el-option :value="2" label="详情页"></el-option>
              <el-option :value="3" label="详情图"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item
          v-if="bannerData.is_link == 2"
          id="jump_where"
          label-width="200px"
          label="跳转位置"
          prop="jump_where"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-select v-model="bannerData.jump_where" style="width: 200px">
              <el-option value="pyramid" label="金字塔"></el-option>
              <el-option value="promotion" label="晋级图"></el-option>
              <el-option value="activity" label="活动"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item
          v-if="bannerData.is_link == 3"
          label="详情图"
          prop="detail_picture"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
            action="#"
            :http-request="httpUploadRequest"
          >
            <img
              v-if="bannerData.detail_picture"
              :src="bannerData.detail_picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          v-if="bannerData.is_link == 1"
          label="banner详情小程序跳转链接"
          prop="link"
          :rules="[
            {
              required: false,
              message: '请输入跳转链接',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            type="text"
            v-model="bannerData.link"
            placeholder="请输入跳转链接"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="bannerData.is_link == 1"
          label="banner详情H5跳转链接"
          prop="link_h5"
          :rules="[
            {
              required: false,
              message: '请输入跳转链接',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            type="text"
            v-model="bannerData.link_h5"
            placeholder="请输入跳转链接"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="bannerVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { MessageBox } from "element-ui";
export default {
  name: "s1_banners",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        status: 0,
      },
      linkObj: {
        1: "链接",
        2: "详情页",
        3: "详情图",
      },
      dataTable: [],
      dataList: [
        {
          prop: "index",
          label: "排序",
          width: 300,
        },
        {
          prop: "kv_picture",
          label: "kvBanner",
          width: 300,
        },
        // {
        //   prop: "detail_picture",
        //   label: "详情Banner",
        //   width: 300,
        // },
      ],
      bannerVisible: false,
      match_data: {},
      bannerData: {
        view_type: "add",
        _id: "",
        index: "",
        kv_picture: "",
        detail_picture: "",
        link: "",
        link_h5: "",
        is_link: 2,
        jump_where: "",
      },
      match: false,
      pager: {
        total: 10,
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = this.match_id + "_" + randomStr() + ".jpg";

      let path = uploadOssUrl().banners + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.bannerData.detail_picture = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    async httpUploadRequestKv(item) {
      this.loading = true;
      let fileName = this.match_id + "_" + randomStr() + ".jpg";

      let path = uploadOssUrl().banners + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.bannerData.kv_picture = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },

    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    clear_content(type) {
      // 链接
      if (type == 1) {
        this.bannerData.link_h5 = "";
        this.bannerData.link = "";
      } else if (type == 2) {
        // 详情页
        this.bannerData.jump_where = "";
      } else if (type == 3) {
        // 详情页
        this.bannerData.detail_picture = "";
      }
    },
    add() {
      this.bannerData._id = "";
      this.setFormData();
      this.bannerVisible = true;
    },
    handleEdit(row) {
      this.bannerData.view_type = "edit";
      this.bannerData._id = row._id;
      this.match_data = row;
      this.setFormData();
      this.bannerVisible = true;
    },
    setFormData() {
      if (this.bannerData._id) {
        Object.keys(this.bannerData).forEach((key) => {
          if (this.match_data[key]) {
            this.bannerData[key] = this.match_data[key];
          }
        });
      } else {
        this.bannerData = {
          view_type: "add",
          _id: "",
          index: "",
          kv_picture: "",
          detail_picture: "",
          link: "",
          link_h5: "",
          is_link: 2,
          jump_where: "",
        };
      }
    },

    saveBanner() {
      this.$refs.bannerForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            _id: this.bannerData._id,
            index: Number.parseInt(this.bannerData.index + ""),
            kv_picture: this.bannerData.kv_picture,
            detail_picture: this.bannerData.detail_picture,
            link: this.bannerData.link,
            link_h5: this.bannerData.link_h5,
            is_link: this.bannerData.is_link,
            jump_where: this.bannerData.jump_where,
          };

          let { data, errorCode } = await this.$http.operate.home_save_banner(
            saveData
          );
          this.loading = false;

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.bannerVisible = false;
          this.handleQuery();
        }
      });
    },
    async handleDelete(_id, status) {
      try {
        let status_title = status == 0 ? "是否删除" : "是否恢复";
        await MessageBox.confirm(status_title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        let new_status = status == 0 ? 1 : 0;
        const { code } = await this.$http.operate.home_status_banner({
          _id: _id,
          status: new_status,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (error) {
        console.log(error);
      }
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    async handleQuery() {
      this.loading = true;
      const { pageNum } = this.pager;
      let pageSize = 10;
      const { data, errorCode } = await this.$http.operate.home_list_banner({
        ...this.searchForm,
        pageNum,
        pageSize,
      });
      this.loading = false;
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query._id;
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
